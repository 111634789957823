const ProjectsContent = [
    {
        imageName: 'landing_mh.jpeg'
    },
    {
        imageName: 'im1.jpg'
    },
    {
        imageName: 'im2.jpg'
    },
    {
        imageName: 'im3.jpg'
    },
    {
        imageName: 'im4.jpg'
    },
    {
        imageName: 'im5.jpg'
    },
    {
        imageName: 'im6.jpg'
    },
    {
        imageName: 'im7.jpg'
    },
    {
        imageName: 'im8.jpg'
    },
    {
        imageName: 'im9.jpg'
    },
    {
        imageName: 'im10.jpg'
    },
    {
        imageName: 'im11.jpg'
    },
    {
        imageName: 'im12.jpg'
    },
    {
        imageName: 'im13.jpg'
    },
    {
        imageName: 'im14.jpg'
    },
    {
        imageName: 'im15.jpg'
    },
    {
        imageName: 'im16.jpg'
    },
    {
        imageName: 'im17.jpg'
    },
    {
        imageName: 'im18.jpg'
    },
    {
        imageName: 'im19.jpg'
    },
    {
        imageName: 'im20.png'
    },
    {
        imageName: 'im21.jpg'
    },
    {
        imageName: 'im22.jpg'
    },
    {
        imageName: 'im23.jpg'
    },
    {
        imageName: 'im24.jpg'
    }
];

export default ProjectsContent;
